<template>
    <div class="educationBg_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <div class="content">
                <div class="mian">
                    <CourseTitle :title="formData.promotionEducationName" contText="">
                    </CourseTitle>
                    <div class="list flex-center column" v-for="(item, index) in formData.educationWhyS" :key="index">
                        <div class="title textAlign">{{ item.educationWhyName }}</div>
                        <div class="list_text textAlign">
                            {{ item.educationWhyIntroduce }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.theFourWaysName" contText="">
                </CourseTitle>
                <div class="main flex">
                    <div class="list_div flex-center column" v-for="(item, index) in formData.educationFourWaysS"
                        :key="index">
                        <img class="list_img" :src="item.educationFourWaysImgUrl" alt="" />
                        <div class="title">{{ item.educationFourWaysName }}</div>
                        <div class="cont">
                            <div class="cont_list" v-for="(items, index) in item.educationWhyIntroduce" :key="index"
                                v-html="items"></div>
                        </div>
                        <div class="consulting display pointer" @click="service">
                            立即咨询
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <CourseTitle :title="formData.promotionPlanName" contText="">
                </CourseTitle>
                <div class="upgrade display">
                    <div class="list flex-center column" v-for="(item, index) in formData.educationPromotionPlanS"
                        :key="index">
                        <img src="@/assets/img/educationBg/5.png" alt="" />
                        <div class="text display">
                            {{ item.educationPromotionPlanName }}
                        </div>
                        <div class="btn display pointer" @click="service">立即咨询</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module4 recommendations">
            <div class="content">
                <CourseTitle :isWhite="1" :title="formData.hotProfessionalRecommendedName" contText="">
                </CourseTitle>
                <div class="mian">
                    <div class="navBar display">
                        <div class="navBarList display column pointer"
                            v-for="(item, index) in formData.educationHotProfessionalCatS" :key="index"
                            @click="changeNavBar(item, index)">
                            <img :src="item.catUrl" alt="" />
                            <div class="name">{{ item.catName }}</div>
                            <div class="line" v-if="navBarIndex == index"></div>
                        </div>
                    </div>
                    <div class="recommendations_div flex">
                        <div class="fl">
                            <img :src="educationHotProfessionalS.hotProfessionalUrl" alt="" />
                        </div>
                        <div class="fr">
                            <div class="list flex" v-if="educationHotProfessionalS &&
                                educationHotProfessionalS.professionalLevel
                                ">
                                <div class="label display">层次</div>
                                <div class="cont_div" v-html="educationHotProfessionalS.professionalLevel"></div>
                            </div>
                            <div class="list flex" v-if="educationHotProfessionalS &&
                                educationHotProfessionalS.professional
                                ">
                                <div class="label display">专业</div>
                                <div class="cont_div professional" v-html="educationHotProfessionalS.professional"></div>
                            </div>
                            <div class="list flex" v-if="educationHotProfessionalS &&
                                educationHotProfessionalS.professionalTerm
                                ">
                                <div class="label display">学制</div>
                                <div class="cont_div" v-html="educationHotProfessionalS.professionalTerm"></div>
                            </div>
                            <div class="list flex" v-if="educationHotProfessionalS &&
                                educationHotProfessionalS.formStudy
                                ">
                                <div class="label display">就读形式</div>
                                <div class="cont_div" v-html="educationHotProfessionalS.formStudy"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <CourseTitle :title="formData.canHeckName" contText=""> </CourseTitle>
                <div class="xuexinNet flex-center">
                    <img class="xuexinNetImg" :src="formData.canHeckImgUrl" alt="" />
                    <div class="xuexinNet_div">
                        <div class="list flex" v-for="(item, index) in formData.educationCanHeckS" :key="index">
                            <div class="num display">{{ index + 1 }}</div>
                            <div class="text">
                                {{ item.introduce }}
                            </div>
                        </div>
                        <div target="_blank" class="queryBtn display pointer" @click="queryBtn(formData.canHeckLink)">
                            学历证书查询
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module6">
            <div class="content">
                <CourseTitle :title="formData.specialFocusName" :contText="formData.specialFocusIntroduce">
                </CourseTitle>
                <div class="registration_div flex flex-wrap">
                    <div class="registrationList" v-for="(item, index) in formData.educationSpecialFocusS" :key="index">
                        <div class="registrationList_div flex-center">
                            <div class="list flex">
                                <div class="data_div display">{{ item.month }}</div>
                                <div class="unit">月</div>
                            </div>
                            <div class="list flex">
                                <div class="data_div display">{{ item.date }}</div>
                                <div class="unit">日</div>
                            </div>
                        </div>
                        <div class="title display">{{ item.specialFocusName }}</div>
                        <div class="remainingTime display">
                            距离报名截止还剩{{ item.remainingDay > 0 ? item.remainingDay : 0 }}天
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module7">
            <div class="content">
                <CourseTitle :title="formData.meAdvantageName" contText="">
                </CourseTitle>
                <div class="advantage">
                    <div class="advantage_list" v-for="(item, index) in formData.educationMeAdvantageS" :key="index">
                        <div class="flex" v-if="index % 2 == 0">
                            <div class="advantage_list_cont">
                                <div class="advantage_list_title">{{ item.advantageName }}</div>
                                <div class="advantage_list_text">
                                    {{ item.advantageIntroduce }}
                                </div>
                                <div class="consultingBtn display pointer" @click="service">
                                    点击咨询
                                </div>
                            </div>
                            <div class="advantage_list_img">
                                <img :src="item.advantageUrl" alt="" />
                            </div>
                        </div>
                        <div v-else class="flex">
                            <div class="advantage_list_img">
                                <img :src="item.advantageUrl" alt="" />
                            </div>
                            <div class="advantage_list_cont">
                                <div class="advantage_list_title">{{ item.advantageName }}</div>
                                <div class="advantage_list_text">
                                    {{ item.advantageIntroduce }}
                                </div>
                                <div class="consultingBtn display pointer" @click="service">
                                    点击咨询
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module8">
            <div class="content">
                <CourseTitle :isWhite="1" :title="formData.faqName" contText="">
                </CourseTitle>
                <div class="answer flex flex-wrap">
                    <div class="answer_list flex-center column" v-for="(item, index) in formData.educationFaqS"
                        :key="index">
                        <div class="answer_title">{{ item.faqName }}</div>
                        <div class="answer_problem row">
                            {{ item.faqIntroduce }}
                        </div>
                        <div class="answer_Btn display pointer" @click="service">
                            点击咨询
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module9">
            <div class="content">
                <CourseTitle title="免费获取报名信息" contText=""> </CourseTitle>
                <div class="registrationInfo">
                    <div class="formBox Between">
                        <div class="list">
                            <input type="text" placeholder="姓名" v-model="params.userName" />
                        </div>
                        <div class="list">
                            <input type="text" placeholder="联系电话" maxlength="11" v-model="params.phoneNumber" />
                        </div>
                    </div>
                    <div class="btn display pointer" @click="submitBtn">立即获取</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
export default {
    components: {
        BannerImg,
        CourseTitle,
    },
    data() {
        return {
            formData: {},
            educationHotProfessionalS: {},
            navBarIndex: 0,
            params: {
                userName: '',
                phoneNumber: '',
                courseId: '',
                courseType: 7,
                courseName: '学历提升',
                email: '',
                companyName: '',
                questionContent: '',
            },
        }
    },
    mounted() {
        this.getData()
        document.documentElement.scrollTop = 0
    },
    methods: {
        getData() {
            this.api.findEducationPromotionCourse().then((res) => {
                if (res.code == 0) {
                    this.educationHotProfessionalS =
                        res.data.educationHotProfessionalCatS[0].educationHotProfessionalS[0]
                    //计算报名时间
                    if (res.data.educationSpecialFocusS.length != 0) {
                        res.data.educationSpecialFocusS.forEach((item) => {
                            if (item.overDate != null && item.overDate) {
                                let arr = []
                                arr = item.overDate.split(' ')
                                arr = arr[0].split('-')
                                item.remainingDay = this.DateDiffer(item.overDate)
                                item.month = arr[1]
                                item.date = arr[2]
                            }
                        })
                    }
                    if (res.data.educationFourWaysS.length != 0) {
                        res.data.educationFourWaysS.forEach((item) => {
                            item.educationWhyIntroduce = item.educationWhyIntroduce.split(';')
                        })
                    }
                    this.formData = res.data
                }
            })
        },

        DateDiffer(Date_end) {
            //date1结束时间
            let date1 = new Date(Date_end)
            //date2当前时间
            let date2 = new Date()
            date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate())
            date2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate())
            const diff = date1.getTime() - date2.getTime() //目标时间减去当前时间
            const diffDate = diff / (24 * 60 * 60 * 1000) //计算当前时间与结束时间之间相差天数
            return diffDate
        },
        //切换导航
        changeNavBar(item, index) {
            this.navBarIndex = index
            this.educationHotProfessionalS = item.educationHotProfessionalS[0]
        },
        //提交报名信息
        submitBtn() {
            this.params.courseId = this.formData.id
            if (this.params.userName == '') {
                this.$message.error('请输入用户姓名')
            } else if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            this.api.addCourseApply(this.params).then((res) => {
                if (res.code == 0) {
                    this.$message.success('成功！')
                    this.params = {
                        userName: '',
                        phoneNumber: '',
                    }
                }
            })
        },
        queryBtn(link) {
            window.open(link, '_blank')
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.educationBg_page {
    .module1 {
        width: 100%;
        height: 728px;

        .content {
            height: 100%;
        }

        .mian {
            width: 1200px;
            height: 100%;
            background: url('../../assets/img/educationBg/bg1.png') no-repeat;
            background-size: 100% 100%;
            position: relative;

            .list:nth-child(2) {
                position: absolute;
                top: 188px;
                left: 104px;
            }

            .list:nth-child(3) {
                position: absolute;
                top: 188px;
                right: 104px;

                .textAlign {
                    text-align: left;
                }
            }

            .list:nth-child(4) {
                position: absolute;
                top: 290px;
                left: 46px;
            }

            .list:nth-child(5) {
                position: absolute;
                top: 290px;
                right: 46px;

                .textAlign {
                    text-align: left;
                }
            }

            .list:nth-child(6) {
                position: absolute;
                top: 392px;
                left: 42px;
            }

            .list:nth-child(7) {
                position: absolute;
                top: 392px;
                right: 42px;

                .textAlign {
                    text-align: left;
                }
            }

            .list:nth-child(8) {
                position: absolute;
                top: 494px;
                left: 79px;
            }

            .list:nth-child(9) {
                position: absolute;
                top: 494px;
                right: 79px;

                .textAlign {
                    text-align: left;
                }
            }

            .list {
                width: 305px;
                min-height: 102px;
                border-bottom: 1px solid #dddddd;
                padding-bottom: 10px;
                padding-top: 14px;

                .textAlign {
                    text-align: right;
                }

                .title {
                    width: 100%;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #003c7e;
                }

                .list_text {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 22px;
                }
            }
        }
    }

    .module2 {
        width: 100%;
        height: 750px;
        background: url('../../assets/img/educationBg/bg2.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 41px;

            .list_div {
                width: 280px;
                height: 480px;
                padding: 30px;
                box-sizing: border-box;
                background: white;
                margin-left: 26px;

                .list_img {
                    width: 80px;
                    height: 80px;
                    background: 50%;
                }

                .title {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #27b07c;
                    margin-top: 20px;
                    line-height: 24px;
                }

                .cont {
                    margin-top: 20px;

                    .cont_list {
                        width: 220px;
                        height: 44px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        border-bottom: 1px dashed #dddddd;
                        line-height: 44px;
                    }
                }

                .consulting {
                    width: 160px;
                    height: 44px;
                    border: 1px solid #27b07c;
                    border-radius: 3px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #27b07c;
                    margin-top: 14px;
                }
            }

            .list_div:nth-child(1) {
                margin-left: 0 !important;
            }

            .list_div:nth-child(2) {
                .title {
                    color: #16b3e6;
                }

                .consulting {
                    color: #16b3e6;
                    border: 1px solid #16b3e6;
                }
            }

            .list_div:nth-child(3) {
                .title {
                    color: #ec7c0e;
                }

                .consulting {
                    color: #ec7c0e;
                    border: 1px solid #ec7c0e;
                }
            }

            .list_div:nth-child(4) {
                .title {
                    color: #e73f3f;
                }

                .consulting {
                    color: #e73f3f;
                    border: 1px solid #e73f3f;
                }
            }
        }
    }

    .module3 {
        height: 520px;

        .upgrade {
            margin-top: 50px;

            .list:nth-child(1) {
                margin-left: 0 !important;

                .text {
                    width: 261px;
                }
            }

            .list:hover {
                width: 280px;
                height: 212px;
                background: linear-gradient(-30deg, #ffffff, #ffffff);
                border: 4px solid #00428b;
                box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.25);
                border-radius: 50px 5px 50px 5px;

                .text {
                    color: #00428b !important;
                }

                .btn {
                    background: linear-gradient(-30deg, #00428b, #006ee7) !important;
                    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25) !important;
                    color: white;
                }
            }

            .list {
                width: 280px;
                height: 212px;
                background: linear-gradient(-30deg, #025fc6, #003c7e);
                border: 1px solid #3186e3;
                border-radius: 50px 5px 50px 5px;
                padding: 23px 15px;
                box-sizing: border-box;
                position: relative;
                margin-left: 26px;

                img {
                    width: 100%;
                    height: 15px;
                }

                .text {
                    width: 244px;
                    height: 55px;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #ffffff;
                    line-height: 30px;
                    margin-top: 34px;
                    text-align: center;
                }

                .btn {
                    width: 160px;
                    height: 45px;
                    background: linear-gradient(-30deg, #ffffff, #ffffff);
                    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 23px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #003c7e;
                    position: absolute;
                    left: 50%;
                    bottom: -23px;
                    margin-left: -80px;
                }
            }
        }
    }

    .module4 {
        height: 790px;
        background: url('../../assets/img/educationBg/bg3.png') no-repeat;
        background-size: 100% 100%;

        .mian {
            margin-top: 38px;

            .navBar {
                .navBarList {
                    width: 105px;
                    margin: 0px 50px;
                    position: relative;

                    img {
                        width: 45px;
                        height: 45px;
                    }

                    .name {
                        font-size: 20px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        margin: 15px 0;
                    }

                    .line {
                        width: 105px;
                        height: 3px;
                        background: #ffffff;
                        border-radius: 2px;
                        position: absolute;
                        left: 0;
                        bottom: 0;
                    }
                }
            }

            .recommendations_div {
                width: 100%;
                height: 428px;
                background: white;
                padding: 38px 25px 40px 40px;
                box-sizing: border-box;
                margin-top: 11px;

                .fl {
                    img {
                        min-width: 520px;
                        height: 350px;
                        margin-right: 25px;
                    }
                }

                .fr {
                    .list {
                        margin-bottom: 20px;

                        .label {
                            min-width: 126px;
                            height: 45px;
                            background: #003c7e;
                            border: 1px solid #003c7e;
                            border-radius: 5px;
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ffffff;
                            line-height: 28px;
                        }

                        .cont_div {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            color: #333333;
                            line-height: 28px;
                            margin-left: 18px;
                            margin-top: 10px;
                        }

                        .professional {
                            width: 432px;
                            max-height: 210px;
                            overflow: hidden;
                            overflow-y: scroll;
                        }

                        .cont_div::-webkit-scrollbar-thumb {
                            background-color: #003c7e;
                            border-left: 2px solid transparent;
                            border-top: 2px solid transparent;
                        }

                        .cont_div::-webkit-scrollbar {
                            width: 3px;
                            height: 3px;
                            background: #f2f2f2;
                        }
                    }
                }
            }
        }
    }

    .module5 {
        height: 640px;

        .xuexinNet {
            margin-top: 22px;
        }

        .xuexinNetImg {
            width: 558px;
            height: 328px;
        }

        .xuexinNet_div {
            height: 328px;
            padding-top: 12px;

            .list {
                margin-top: 32px;

                .num {
                    min-width: 34px;
                    height: 23px;
                    background: #003c7e;
                    border: 1px solid #003c7e;
                    border-radius: 3px;
                    font-size: 16px;
                    color: #fff;
                    margin-right: 8px;
                }

                .text {
                    width: 540px;
                }
            }

            .queryBtn {
                width: 248px;
                height: 48px;
                background: #ff881e;
                border-radius: 3px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-top: 44px;
                margin-left: 41px;
            }
        }
    }

    .module6 {
        height: 880px;
        background: url('../../assets/img/educationBg/bg2.png') no-repeat;
        background-size: 100% 100%;

        .registration_div {
            margin-top: 40px;
            justify-content: center;

            .registrationList {
                width: 435px;
                height: 277px;
                background: #ffffff;
                border-radius: 20px;
                padding-top: 22px;
                box-sizing: border-box;
                margin: 0px 40px;
                margin-bottom: 56px;

                .registrationList_div {
                    justify-content: center;

                    .list {
                        margin: 0px 11px;

                        .data_div {
                            width: 126px;
                            height: 126px;
                            background: url('../../assets/img/educationBg/date-bg.png') no-repeat;
                            background-size: 100% 100%;
                            font-size: 57px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ffffff;
                        }

                        .unit {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #003c7e;
                            margin-top: 99px;
                        }
                    }
                }
            }

            .title {
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                margin-top: 32px;
                line-height: 24px;
            }

            .remainingTime {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ff881e;
                margin-top: 16px;
                line-height: 18px;
            }
        }
    }

    .module7 {
        height: 1608px;

        .content {
            height: 1608px;
            background: url('../../assets/img/educationBg/bg5.png') no-repeat;
            background-size: 100% 100%;
        }

        .advantage {
            margin-top: -110px;
            position: relative;

            .advantage_list:nth-child(1) {
                top: 220px;
                left: 225px;

                .advantage_list_cont {
                    margin-right: 40px;
                }
            }

            .advantage_list:nth-child(2) {
                top: 530px;
                left: 115px;

                .advantage_list_cont {
                    margin-left: 56px;
                }
            }

            .advantage_list:nth-child(3) {
                top: 845px;
                left: 225px;

                .advantage_list_cont {
                    margin-right: 40px;
                }
            }

            .advantage_list:nth-child(4) {
                top: 1165px;
                left: 130px;

                .advantage_list_cont {
                    margin-left: 56px;
                }
            }

            .advantage_list {
                height: 228px;
                position: absolute;

                .advantage_list_cont {
                    padding-top: 22px;

                    .advantage_list_title {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #003c7e;
                        margin-bottom: 24px;
                    }

                    .advantage_list_text {
                        width: 400px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-bottom: 24px;
                    }

                    .consultingBtn {
                        width: 248px;
                        height: 48px;
                        background: #ff881e;
                        border-radius: 3px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }
            }

            .advantage_list_img {
                img {
                    min-width: 398px;
                    height: 228px;
                }
            }
        }
    }

    .module8 {
        height: 950px;
        background: url('../../assets/img/educationBg/bg4.png') no-repeat;
        background-size: 100% 100%;

        .answer {
            margin-top: 11px;

            .answer_list:nth-child(4n + 1) {
                margin-left: 0 !important;
            }

            .answer_list {
                width: 260px;
                height: 210px;
                background: #ffffff;
                padding: 0px 20px;
                padding-top: 24px;
                box-sizing: border-box;
                margin-left: 30px;
                margin-bottom: 30px;

                .answer_title {
                    width: 200px;
                    height: 65px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #003c7e;
                    line-height: 28px;
                    text-align: center;
                    border-bottom: 1px solid #dddddd;
                    padding-bottom: 16px;
                }

                .answer_problem {
                    width: 191px;
                    height: 18px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin: 20px 0;
                }

                .answer_Btn {
                    width: 120px;
                    height: 40px;
                    background: #ff881e;
                    border-radius: 3px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }
    }

    .module9 {
        height: 402px;

        .registrationInfo {
            width: 670px;
            margin: 0 auto;
            margin-top: 21px;

            .formBox {
                width: 100%;

                .list {
                    input {
                        width: 323px;
                        height: 54px;
                        background: #f4f5f5;
                        font-size: 16px;
                        padding-left: 20px;
                        box-sizing: border-box;
                    }
                }
            }

            .btn {
                width: 671px;
                height: 54px;
                background: #ff881e;
                border-radius: 3px 0px 0px 0px;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-top: 26px;
            }
        }
    }
}</style>